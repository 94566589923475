const sizes = {
  tablet: '1200px',
  phone: '600px',
};

export const theme = {
  colors: {
    primay_light_pink: '#E85D75',
    primay_dark_blue: '#201E20',
    primary_light_gray: '#DEDEDE',
    gray: 'rgb(245,250,249)',
  },

  media: {
    tablet: `(max-width: ${sizes.tablet})`,
    phone: `(max-width: ${sizes.phone})`,
  },
};
